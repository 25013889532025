<script setup>
import { computed, onMounted, ref } from 'vue'
import Eye from 'vue-material-design-icons/Eye.vue';
import EyeOff from 'vue-material-design-icons/EyeOff.vue';
import { checkEmail } from '@/utils/common';

const passwordVisible = ref(false)
const wrapper = ref(null)
const input = ref(null)

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  special: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
    default: '',
  },
  underlineFirstText: {
    type: String,
    default: '',
  },
  underlineFirstType: {
    type: String,
    default: '',
  },
  underlineFirstLink: {
    type: String,
    default: '',
  },
  underlineLastText: {
    type: String,
    default: '',
  },
  underlineLastType: {
    type: String,
    default: '',
  },
  password: {
    type: Boolean,
    default: false,
  },
  email: {
    type: Boolean,
    default: false,
  },
  valid: {
    type: Boolean,
    default: true,
  },
})

function onInput($event) {
  emit('update:modelValue', $event.target.value)
  if (props.email) {
    emit('update:valid', checkEmail($event.target.value))
  }
}

const inputType = computed(() => {
  return (props.password && !passwordVisible.value) ? 'password' : 'text'
})

function passwordSwitchClick() {
  passwordVisible.value = !passwordVisible.value
}

const emit = defineEmits(['update:modelValue', 'update:valid', 'underlineFirstClick', 'underlineLastClick'])

onMounted(() => {
  if (props.autofocus) {
    input.value.focus()
  }
})

defineExpose({
  wrapper,
  input,
})
</script>

<template>
  <div class="text-input__wrapper" ref="wrapper">
    <label class="label">
      {{ label }}
    </label>
    <div class="input__wrapper">
      <input
        :type="inputType"
        :placeholder="placeholder"
        class="input input-text"
        :class="{'input--special': special}"
        :value="modelValue"
        @input="onInput"
        ref="input"
      >
      <div class="password-switch" v-if="password" @click="passwordSwitchClick">
        <Eye v-if="passwordVisible" :size="22"/>
        <EyeOff v-else :size="22"/>
      </div>
    </div>
    <div class="underline__wrapper">
      <p
        class="underline-item underline-item--first"
        :class="{ 'underline-item--error': underlineFirstType == 'error', 'underline-item--link': underlineLastType == 'link' }"
        @click.stop="emit('underlineFirstClick')"
      >
        {{ underlineFirstText }}
      </p>

      <p
        class="underline-item underline-item--last"
        :class="{ 'underline-item--error': underlineLastType == 'error', 'underline-item--link': underlineLastType == 'link' }"
        @click.stop="emit('underlineLastClick')"
      >
        {{ underlineLastText }}
      </p>
    </div>
  </div>
</template>

<style scoped>
.text-input__wrapper:not(:first-child) {
  margin-top: 13px;
}

.label {
  font: normal 12px 'Inter';
  color: #495F71;
  height: 19px;
}

.input__wrapper {
  position: relative;
}

.input {
  font: normal 18px "Inter";

  background: white;
  border-radius: 6px;
  height: 42px;
  color: #000000;
  padding-left: 18px;
  padding-right: 40px;
  font: 400 20px 'Inter';
  width: 100%;
  text-align: start;
  border: none;
  outline: none;
  border: 1px solid #CACACA;
  margin-top: 4px;
}

.input--special {
  height: 60px;
  filter: drop-shadow(0px 16px 32px rgba(34, 34, 34, 0.26));
}

.password-switch {
  position: absolute;
  top: 50%;
  right: 11px;
  transform: translateY(-50%);
  cursor: pointer;
  color: black;
}

.underline__wrapper {
  display: flex;
  flex-direction: row;
  padding-top: 3px;
  min-height: 18px;
}

.underline-item--last {
  margin-left: auto;
}

.underline-item {
  font: 400 10px 'Inter';
  color: white;
}

.underline-item--link {
  font: normal 12px 'Inter';
  color: #686868;
  text-decoration: none;
  cursor: pointer
}

.underline-item--link:hover {
  color: #00D7FF;
}

.underline-item--error {
  color: #EB4C5E;
}

.password-switch :deep(.material-design-icon__svg) {
  display: block;
}
</style>
