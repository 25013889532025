import { isSafari } from '@/utils/common'
import {getSpaceData} from "@/utils/settings";

function navigateTo(url) {
  window.location.href = url;
}

function navigateToNoHistory(url) {
  window.location.replace(url)
}

function navigateToNoReload(url) {
  history.pushState(null, null, url)
}

function navigateToDefaultUrl() {
  navigateToNoReload(getSpaceData().public.default_url)
}

function OpenUrl(url) {
  window.open(url, isSafari ? "_self" : "_blank");
}

function copyToClipboard(text) {
  navigator.clipboard.writeText(window.location.href + text)
}

function logDebugInfo(info) {
  console.debug(info)
}

function setSpaceName(name) {
  document.title = `${name} | W3rlds`
}

window.navigateTo = navigateTo
window.navigateToNoHistory = navigateToNoHistory
window.navigateToNoReload = navigateToNoReload
window.navigateToDefaultUrl = navigateToDefaultUrl
window.OpenUrl = OpenUrl
window.copyToClipboard = copyToClipboard
window.logDebugInfo = logDebugInfo
window.setSpaceName = setSpaceName // Unused?, document.title is used in TheSpace.vue also
