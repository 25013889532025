<script setup>
const emit = defineEmits(['rpm']);
</script>

<template>
  <p class="change-avatar-title">
    Choose ready avatar<br class="title__mobile-break"/> or
    <a href="#" @click.stop.prevent="emit('rpm')" class="title__link">
      Customize your look
    </a>
  </p>
</template>

<style scoped>

.change-avatar-title {
  font: 500 18px/22px 'Inter';
  text-align: center;
  color: #000000;
}

.title__link {
  font: 600 18px/22px 'Inter';
  text-decoration-line: underline;
  color: #00D7FF;
}

.title__mobile-break {
  display: none;
}

@media (max-width: 992px) {
  .title__mobile-break {
    display: block;
  }
}

</style>
