import { sendMessageToUnity } from '@/utils/unityBridgeBase';

let canvas

export function startOrientationUnityNotification() {
  canvas = document.getElementById('unity-canvas')

  sendScreenParamsToUnity()

  window.addEventListener('resize', onResize);
}

export function stopOrientationUnityNotification() {
  window.removeEventListener('resize', onResize);
}

function onResize() {
  sendScreenParamsToUnity();
}
    
const sendScreenParamsToUnity = () => {
  const screenParams = getScreenParams()
  sendMessageToUnity('UpdateScreenParams', JSON.stringify(screenParams))
}

const getScreenParams = () => {
  return {
    isLandscape: canvas.innerWidth > canvas.innerHeight ? 1 : 0,
    width: canvas.innerWidth,
    height: canvas.innerHeight,
  }
}
