import { gtag, install } from 'ga-gtag'
import {getSpaceData} from "@/utils/settings";

export const timeStart = Date.now() / 1000
let timeCoreLoaded = 0

install(
  getSpaceData().ga_token,
  {
    'send_page_view': false,
    'custom_map': {
      'metric1': 'avg_space_load_time_full',
      'metric2': 'avg_space_load_time_core',
      'metric3': 'avg_space_load_time_data',
      'metric4': 'avg_space_tab_opened_time',
    },
  }
)

gtag("event", "page_view", {
  page_title: document.title,
  page_location: location.href,
  page_path: location.pathname,
});

export function trackCoreLoaded() {
  timeCoreLoaded = Date.now() / 1000
  gtag('event', 'space_load_time_core_metric', { 'avg_space_load_time_core': timeCoreLoaded - timeStart })
}

export function trackSpaceEntered() {
  gtag("event", "space_entered")
}

export function trackAllLoaded() {
  const timeFullyLoaded = Date.now() / 1000

  gtag("event", "space_loaded")
  gtag('event', 'space_load_time_full_metric', { 'avg_space_load_time_full': timeFullyLoaded - timeStart })
  gtag('event', 'space_load_time_data_metric', { 'avg_space_load_time_data': timeFullyLoaded - timeCoreLoaded })
}

export function trackUnload() {
  const timeUnload = Date.now() / 1000

  gtag('event', 'space_tab_opened_time_metric', { 'avg_space_tab_opened_time': timeUnload - timeStart })
}
