<script setup>
import '@/utils/visibility.js'

import '@/utils/unityBridgeUI.js'
import '@/utils/unityBridgeVoice.js'
import '@/utils/unityBridgeUtils.js'
import '@/utils/unityBridgeCustomization.js'
import '@/utils/analytics'

import '@/assets/notifications.scss'

import { computed } from 'vue'
import { getSpaceData } from '@/utils/settings.js'
import { isMobile } from '@/utils/common'

import TheSpace from '@/components/TheSpace.vue'
import NoMobile from '@/components/NoMobile.vue'


const noMobileBannerVisible = computed(() => {
  const spaceData = getSpaceData()

  return isMobile && !spaceData.space.data.glbmodel_has_mobile
})
</script>

<template>
  <NoMobile v-if="noMobileBannerVisible" />
  <TheSpace v-else/>
</template>

<style scoped>
.app {
  width: 100%;
  height: 100%;
}
</style>
