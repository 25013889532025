import { sendMessageToUnity } from '@/utils/unityBridgeBase'

let dialogsCount = 0

export function incrementDialogsCount() {
  if (dialogsCount === 0) {
    unityInputCapture(false)
  }

  dialogsCount++
}

export function decrementDialogsCount() {
  dialogsCount--

  if (dialogsCount === 0) {
    unityInputCapture(true)

    window.unityCanvas.focus()
  }
}

function unityInputCapture(enabled) {
  if (enabled) {
    sendMessageToUnity('StartCaptureKeyboardInput')
  } else {
    sendMessageToUnity('StopCaptureKeyboardInput')
  }
}