import './utils/settings'

import { createApp } from 'vue'

import Notifications from '@kyvg/vue3-notification'

import App from './App.vue'

import './assets/main.css'
import { loadFonts } from '@/utils/webfontloader'
import '@/utils/webgl-force-high-performance.js'
import {removeSaTokenFromUrl} from "@/utils/saToken";

loadFonts()
removeSaTokenFromUrl()

const app = createApp(App)
app.use(Notifications)
app.mount('#app')
