
import { sendMessageToUnity } from '@/utils/unityBridgeBase';

function isPointerLockElementCanvas() {
  const pointerLockElement = document.pointerLockElement || document.mozPointerLockElement || document.webkitPointerLockElement || document.msPointerLockElement;
  console.log('pointerLockElement: ' + pointerLockElement);

  const result = pointerLockElement === window.unityCanvas;
  console.log('isPointerLockElementCanvas: ' + result);

  return result;
}

function lockChangeAlert() {
  if (isPointerLockElementCanvas()) {
    console.log('The pointer lock status is now locked');
    sendMessageToUnity ('EnterMouseLockMode');
  } else {
    console.log('The pointer lock status is now unlocked');
    sendMessageToUnity('ExitMouseLockMode');
  }
}

export function startPointerLockUnityNotification() {
  document.addEventListener('pointerlockchange', lockChangeAlert, false);
  document.addEventListener('mozpointerlockchange', lockChangeAlert, false);
  document.addEventListener('webkitpointerlockchange', lockChangeAlert, false);
  document.addEventListener('mspointerlockchange', lockChangeAlert, false);
  document.addEventListener('mspointerlockchange', lockChangeAlert, false);
}

export function stopPointerLockUnityNotification() {
  document.removeEventListener('pointerlockchange', lockChangeAlert);
  document.removeEventListener('mozpointerlockchange', lockChangeAlert);
  document.removeEventListener('webkitpointerlockchange', lockChangeAlert);
  document.removeEventListener('mspointerlockchange', lockChangeAlert);
  document.removeEventListener('mspointerlockchange', lockChangeAlert);
}