
<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { computed } from 'vue'

const props = defineProps({
  progress: {
    type: Number,
    required: true,
  },
})

const widthStyle = computed(() => {
  return `${props.progress}%`
})
</script>

<template>
  <div class="progress-bar__wrapper">

    <div class="bar-empty">
      <div class="bar-full" :style="{ width: widthStyle }"></div>
    </div>

  </div>
</template>

<style scoped>

.bar-empty {
  width: 100%;
  height: 3px;
  flex-shrink: 0;
  margin: 0 auto;
  background-color: #4c4c4c;
  border-radius: 3px;
  margin-top: 28px;
}

.bar-full {
  width: 0%;
  height: 100%;
  background: linear-gradient(270deg, #38FDEE 0%, #00C2FF 39.06%, #D663FF 100%);
  border-radius: 3px;
}
</style>