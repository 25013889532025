import emitter from './emitter'
import { sendMessageToUnity } from '@/utils/unityBridgeBase';

export function startVoiceReconnectUnityNotification() {
  emitter.on('onVisibilityChanged', (isVisible) => {
    const command = isVisible ? 'RequireVoiceReconnect' : 'RequireVoiceDisconnect';

    sendMessageToUnity(command)
  })
}

export function stopVoiceReconnectUnityNotification() {
  emitter.off('onVisibilityChanged')
}
