<script setup>
import {ref, onMounted, computed, onBeforeUnmount, shallowRef, watch} from 'vue'
import emitter from '@/utils/emitter'
import { getSpaceData, contentUrl } from '@/utils/settings'
import { TextInput, Button } from '@/components/inputs'
import AvatarsSlider from '@/components/AvatarsSlider.vue'
import ChangeAvatarTitle from '@/components/ChangeAvatarTitle.vue'
import RestrictAccess from "@/components/RestrictAccess.vue"
import {useNotification} from "@kyvg/vue3-notification"

import { trackCoreLoaded, trackAllLoaded, trackUnload, trackSpaceEntered } from '@/utils/analytics'
import { incrementDialogsCount, decrementDialogsCount } from '@/utils/inputCapture'
import { getRandomInt } from '@/utils/common'
import { currentAvatar, sendMessageSelectAvatar } from '@/utils/customization.js'
import { sendMessageSetNickname } from '@/utils/customization.js'
import Rpm from '@/components/Rpm.vue'
import { LoaderWindow } from '@/components/loader'

const { notify }  = useNotification()

const spaceData = shallowRef(getSpaceData().space.data)
const isAccessAllowed = ref(getSpaceData().space.auth_requirement === null)

const wrapper = ref(null)
const enterButton = ref(null)
const nicknameInput = ref(null)

const selectedAvatar = ref(null)

const isRpmVisible = ref(false)

/*global createUnityInstance*/

const spaceLoaded = ref(false)
const settingsExpanded = ref(false)

const windowWidth = ref(window.innerWidth)
const isMobile = computed(() => windowWidth.value <= 992)

const props = defineProps({
  canvas: {
    type: Object,
    required: true,
  },
})

const loaderWindow = ref(null)

const isCustomBackground = computed(() => {
  return spaceData.value?.theme.enabled &&
    spaceData.value.theme.loader?.enabled &&
    spaceData.value.theme.loader?.background_image
})

const customBackgroundStyle = computed(() => {
  // XXX: read all required fields unconditionally, because for first run vue caches dependencies.
  const backgroundImage = spaceData.value?.theme.loader?.background_image
  if (isCustomBackground.value) {
    return { 'background-image': `url(${contentUrl}/${backgroundImage})` }
  }
  else {
    return {}
  }
})

const customNicknameDisabled = computed(() => {
  return spaceData.value.custom_nicknames_disabled
})

const unityConfig = computed(() => {
  const spaceData = getSpaceData()
  // This is special unity parameters https://docs.unity3d.com/Manual/webgl-templates.html
  return {
    dataUrl: spaceData.unity.data_url,
    frameworkUrl: spaceData.unity.framework_url,
    codeUrl: spaceData.unity.code_url,
    streamingAssetsUrl: 'StreamingAssets',
    companyName: 'W3rlds',
    productName: 'W3rlds Core',
    productVersion: spaceData.app.version,
  }
})

const nickname = ref('')

function enterWorld() {
  sendMessageSetNickname(nickname.value)

  let avatar = null
  if (selectedAvatar.value != null) {
    avatar = selectedAvatar.value.data
  } else {
    if (!currentAvatar.type) {
      // if RPM was not selected
      const avatars = spaceData.value.avatars
      if (avatars.length !== 0) {
        avatar = avatars[getRandomInt(0, avatars.length)]
      }
    }
  }

  if (avatar) {
    sendMessageSelectAvatar(avatar)
  }

  trackSpaceEntered()

  emit('enter')
}

async function loadDevUnityLoader() {
  const script = document.createElement('script')
  script.src = import.meta.env.VITE_DEV_UNITY_LOADER_URL
  script.async = true

  document.body.appendChild(script)

  // XXX: is it too late to set event handlers after inserted in DOM?
  await new Promise(
    (resolve, reject) => {
      script.onerror = reject
      script.onload = resolve
    }
  )
}

emitter.on('onWorldLoadingFinished', () => {
  spaceLoaded.value = true

  trackAllLoaded();
})

emitter.on('setSpaceData', (jsonSpaceData) => {
  spaceData.value = jsonSpaceData
})

async function onSetupClick() {
  settingsExpanded.value = true
  nicknameInput.value.input.focus()
}

onMounted(async () => {
  addEventListener("resize", () => { windowWidth.value = window.innerWidth })

  watch(isAccessAllowed, async function (newValue, oldValue) {
    if (oldValue || newValue) {
      if (typeof createUnityInstance === 'undefined') {
        await loadDevUnityLoader()
      }

      window.unityObj = await createUnityInstance(props.canvas, unityConfig.value, loaderWindow.value.setProgress)

      emit('unityLoaded')

      incrementDialogsCount()

      trackCoreLoaded()
    }
  }, { immediate: true})
})

onBeforeUnmount(() => {
  decrementDialogsCount()

  trackUnload()
})

const emit = defineEmits(['enter', 'unityLoaded'])

</script>

<template>
  <notifications position="top center"/>
  <div
    class="loader__wrapper"
    :class="{ 'custom-background': isCustomBackground }"
    :style="customBackgroundStyle"
    ref="wrapper"
  >
    <div class="loader-logo"></div>

    <div v-if="!isAccessAllowed" class="restrict-access__wrapper">
      <RestrictAccess @accessAllowed="isAccessAllowed = true" @notify="notify($event)"></RestrictAccess>
    </div>

    <LoaderWindow
      mode="load-core"
      ref="loaderWindow"
    >
      <template #header v-if="isAccessAllowed">
        <div class="settings__wrapper" :class="{ expanded: settingsExpanded, 'custom-nickname-disabled': customNicknameDisabled }">
          <TextInput
            placeholder="Whats your name?"
            special
            v-model="nickname"
            @keydown.enter="enterButton.button.click()"
            ref="nicknameInput"
            v-if="!customNicknameDisabled"
            class="nickname-input"
          />

          <ChangeAvatarTitle
            @rpm="isRpmVisible = true"
          />

          <AvatarsSlider
            class="avatars-slider"
            v-model="selectedAvatar"
            :showMore="true"
          />
        </div>
      </template>

      <template #footer>
        <div class="buttons__wrapper">
          <Button
            :large="!isMobile"
            :xxlarge="isMobile"
            :disabled="!spaceLoaded || !isAccessAllowed"
            @click="enterWorld"
            ref="enterButton"
          >
            Enter the World
          </Button>

          <Button
            :large="!isMobile"
            :xxlarge="isMobile"
            gradient
            @click="onSetupClick"
            :disabled="!isAccessAllowed"
          >
            Setup your avatar
          </Button>
        </div>
      </template>
    </LoaderWindow>

    <Rpm
      v-if="isRpmVisible && isAccessAllowed"
      @close="isRpmVisible = false"
    />
  </div>
</template>

<style scoped>

.loader__wrapper {
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: url('@/assets/images/loader-bg.jpg') no-repeat center center;
  background-size: cover;
}

.buttons__wrapper {
  margin-top: 28px;
  display: flex;
  flex-direction: row;
  gap: 28px;
}

@media (max-width: 992px) {
  .buttons__wrapper {
    flex-direction: column;
    gap: 20px;
  }
}

.settings__wrapper {
  display: flex;
  flex-direction: column;
  height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.settings__wrapper.expanded {
  height: 332px;
  overflow: visible;
  opacity: 1;
}

.settings__wrapper.custom-nickname-disabled.expanded {
  height: 222px;
}

@media (max-width: 992px) {
  .settings__wrapper.expanded {
    height: 355px;
    overflow: visible;
    opacity: 1;
  }

  .settings__wrapper.custom-nickname-disabled.expanded {
    height: 245px;
  }
}

.nickname-input {
  margin-bottom: 36px;
}

.avatars-slider {
  margin-top: 28px;
  margin-bottom: 34px;
}

@media (max-width: 992px) {
  .btn {
    width: 100%;
    display: block;
  }
}

.loader__wrapper .restrict-access__wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  .restrict-access {
    position: relative;
    max-width: 527px;
    width: 100%;
  }
}

@media (max-width: 992px) {
  .loader__wrapper {
    display: grid;
    grid-template-rows: auto 1fr auto;

    .loading-panel__wrapper {
      position: static;
      width: initial;
    }

    .restrict-access__wrapper {
      position: static;
      margin: 0 auto;
      align-content: center;

      .restrict-access {
        margin: 12px;
      }
    }
  }
}

</style>
