<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { TheLoader } from '@/components/loader'
import LoginDialog from '@/components/login/LoginDialog.vue';
import emitter from '@/utils/emitter'
import { startOrientationUnityNotification, stopOrientationUnityNotification } from '@/utils/orientation'
import { startPointerLockUnityNotification, stopPointerLockUnityNotification } from '@/utils/pointerLock'
import { startVoiceReconnectUnityNotification, stopVoiceReconnectUnityNotification } from '@/utils/voiceReconnect'
import UnityBanner from '@/components/UnityBanner.vue'
import ChangeNicknameDialog from './ChangeNicknameDialog.vue'
import ChangeAvatarDialog from './ChangeAvatarDialog.vue'
import { LoaderWindow } from '@/components/loader'
import { getSpaceData } from '@/utils/settings.js'

const canvas = ref()
const loader = ref()
const entered = ref(false)

const bannerVisible = ref(false)
const bannerText = ref('')
const bannerType = ref('')

const loginDialogVisible = ref(false)
const changeNicknameDialogVisible = ref(false)
const changeAvatarVisible = ref(false)
const loaderWindowVisible = ref(false)

emitter.on('showAuthWindow', () => {
  loginDialogVisible.value = true
})

emitter.on('showNicknameChangeWindow', () => {
  changeNicknameDialogVisible.value = true
})

emitter.on('unityShowBanner', (msg, type) => {
  bannerText.value = msg
  bannerType.value = type
  bannerVisible.value = true
})

emitter.on('unityHideBanner', () => {
  bannerText.value = ''
  bannerType.value = ''
  bannerVisible.value = false
})

emitter.on('showAvatarChangeWindow', () => {
  changeAvatarVisible.value = true
})

emitter.on('showWindowLoading', (isVisible) => {
  loaderWindowVisible.value = !!isVisible
})

emitter.on('setSpaceData', (jsonSpaceData) => {
  getSpaceData().space.data = jsonSpaceData

  const name = getSpaceData().space.data.name
  document.title = `${name} | W3rlds`
})

emitter.on('onUnityReady', () => {
  startOrientationUnityNotification()
  startPointerLockUnityNotification()
  startVoiceReconnectUnityNotification()
})

onMounted(() => {
  window.unityCanvas = canvas.value
})

onBeforeUnmount(() => {
  stopOrientationUnityNotification()
  stopPointerLockUnityNotification()
  stopVoiceReconnectUnityNotification()
})
</script>

<template>
  <div class="space__wrapper">
    <canvas id="unity-canvas" class="canvas" ref="canvas" tabindex="-1"></canvas>

    <TheLoader
      ref="loader"
      :canvas="canvas"
      v-if="canvas && !entered"
      @enter="entered = true"
    />

    <LoginDialog
      v-if="loginDialogVisible"
      @close="loginDialogVisible = false"
    />

    <ChangeNicknameDialog
      v-if="changeNicknameDialogVisible"
      @close="changeNicknameDialogVisible = false"
    />

    <ChangeAvatarDialog
      v-if="changeAvatarVisible"
      @close="changeAvatarVisible = false"
    />

    <UnityBanner
      v-if="bannerVisible"
      :type="bannerType"
      :text="bannerText"
    />

    <LoaderWindow
      v-if="loaderWindowVisible"
    />
  </div>
</template>

<style scoped>
.space__wrapper {
  width: 100%;
  height: 100%;
}

.canvas {
  width: 100%;
  height: 100%;
  display: block;
}
</style>
