<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
</script>

<template>
  <p class="login-title">
    <slot></slot>
  </p>
</template>

<style scoped>
.login-title {
  font: 500 28px/34px 'Inter';
  color: #000000;
  text-align: center;
}
</style>
