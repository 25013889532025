<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { onMounted, onBeforeUnmount } from 'vue';
import CloseIcon from 'vue-material-design-icons/Close.vue';
import { incrementDialogsCount, decrementDialogsCount } from '@/utils/inputCapture'

defineProps({
  title: {
    type: String,
    default: '',
  },
  pads: {
    type: String,
    default: 'thick',
  },
})

function keydown(e) {
  if (e.keyCode === 27) {
    emit('close')
  }
}

const emit = defineEmits(['close']);

onMounted(() => {
  incrementDialogsCount()
})

onBeforeUnmount(() => {
  decrementDialogsCount()
})
</script>

<template>
  <div class="overlay"
    @click.stop=""
    @contextmenu.stop=""
    @dblclick.stop=""
    @mousedown.stop=""
    @mouseenter.stop=""
    @mouseleave.stop=""
    @mousemove.stop=""
    @mouseout.stop=""
    @mouseover.stop=""
    @mouseup.stop=""
    @keydown.stop="keydown"
    @keypress.stop=""
    @keyup.stop=""
    tabindex="0"
  >
    <div class="dialog__wrapper" :class="{ 'pads-thin': pads === 'thin' }">
      <CloseIcon
        class="btn-close"
        @click="emit('close')"
        :size="29"
      />

      <div class="title-content__wrapper">
        <h3 class="title" v-if="title">{{ title }}</h3>

        <div class="content__wrapper">
          <slot></slot>
        </div>
      </div>

      <div class="footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.dialog__wrapper {
  font: normal 16px/1.4 "Inter", sans-serif;
  position: relative;

  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(19px);
  border-radius: 14px;
  padding: 46px 88px 46px;
  width: 100%;
  max-width: 527px;
  margin: auto;
  display: flex;
  flex-direction: column; 
}

.pads-thin {
  padding: 55px;
}

@media (max-width: 992px) {
  .dialog__wrapper {
    background-color: white;

    max-width: none;
    height: 100%;
    border: none;
    box-shadow: none;
    border-radius: 0;

    padding: 33px 24px;
  }
}

@media screen and (max-width: 992px) {
  .title-content__wrapper {
    padding-bottom: 10%;
    margin: auto;
  }
}

@media screen and (max-width: 992px) {
  .content__wrapper {
    margin-top: 33px;
  }
}

.btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.title {
  font: 500 28px 'Inter';
  color: #000000;
}

@media (max-width: 992px) {
  .title {
    font: 500 26px 'Inter';
    text-align: center;
  }
}

@media (max-width: 992px) {
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}


</style>
