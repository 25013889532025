import voiceAgora from '@/utils/voiceAgora'

function voiceInit() {
  voiceAgora.voiceInit()
}

function voiceGetConnectionState() {
  return voiceAgora.voiceGetConnectionState()
}

function voiceJoinRoom(appid, room, token) {
  return voiceAgora.voiceJoinRoom(appid, room, token)
}

function voiceLeaveRoom() {
  return voiceAgora.voiceLeaveRoom()
}

function voiceRenewToken(token) {
  voiceAgora.voiceRenewToken(token)
}

function voiceSetMuted(muted) {
  voiceAgora.voiceSetMuted(muted)
}

window.voiceInit = voiceInit
window.voiceGetConnectionState = voiceGetConnectionState
window.voiceJoinRoom = voiceJoinRoom
window.voiceLeaveRoom = voiceLeaveRoom
window.voiceRenewToken = voiceRenewToken
window.voiceSetMuted = voiceSetMuted
