import emitter from '@/utils/emitter'
import { sendMessageToUnity } from '@/utils/unityBridgeBase'
import {apiUrl, contentUrl, getSpaceData} from "@/utils/settings";

function onCompleteLoadingStage(stageNumber, stageCount) {
  console.log('UnityToBrowser', 'onCompleteLoadingStage', stageNumber, stageCount)
  emitter.emit('onCompleteLoadingStage', stageNumber, stageCount)
}

function onWorldLoadingFinished() {
  console.log('UnityToBrowser', 'onWorldLoadingFinished')
  emitter.emit('onWorldLoadingFinished')
}

function onUnityReady() {
  console.log('UnityToBrowser', 'onWorldLoadingFinished')
  emitter.emit('onUnityReady')
}

function getSpaceRoomId() {
  console.log('UnityToBrowser', 'getSpaceRoomId')
  let invitationId = getQueryParam('invite')
  if (invitationId) {
    sendMessageToUnity('OnGotInvitationLink', invitationId)
  }

  const spaceData = getSpaceData()
  sendMessageToUnity('OnGotSpaceId', JSON.stringify({
    spaceId: spaceData.space.id,
    // Unity requests /render/space/ and does not have any interest in our.
    // spaceData: spaceData.space.data,
    authHeader: spaceData.space.auth_token || null,
    authQueryParam: spaceData.space.access_token || null,
  }))
}

function getInitProperties() {
  console.log('UnityToBrowser', 'getInitProperties')
  const eventId = getQueryParam('invite')
  const spaceData = getSpaceData()
  sendMessageToUnity('OnGetInitProperties', JSON.stringify({
    // Assets/Scripts/WebGl/BrowserBridge.cs:BrowserInitProperties
    spaceId: spaceData.space.id || null,
    eventId: eventId || null,
    // Unity requests /render/space/ and does not have any interest in our.
    // spaceData: spaceData.space.data,
    authHeader: spaceData.space.auth_token || null,
    authQueryParam: spaceData.space.access_token || null,
    urlApi: apiUrl,
    urlContent: contentUrl,
  }))
}

function showStartWindow() {
}

function showWindowLoading(isVisible) {
  console.log('UnityToBrowser', 'showWindowLoading', isVisible);
  emitter.emit('showWindowLoading', isVisible)
}

function showAuthWindow() {
  console.log('UnityToBrowser', 'showAuthWindow')
  emitter.emit('showAuthWindow')
}

// isError: 1 - error; 0 - success
function onLoginResult(isError, errorText = '') {
  console.log('UnityToBrowser', 'onLoginResult', isError, errorText)
  emitter.emit('onLoginResult', !!isError, errorText)
}

// isError: 1 - error; 0 - success
function onResetPasswordResult(isError, errorText = '') {
  console.log('UnityToBrowser', 'onResetPasswordResult', isError, errorText)
  emitter.emit('onResetPasswordResult', !!isError, errorText)
}

let timeoutId = null
function unityShowBanner(msg, type, timeout = 5000) {
  console.log('UnityToBrowser', 'unityShowBanner', msg, type, timeout)
  emitter.emit('unityShowBanner', msg, type)

  if (type == 'warning' || type == 'info') {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => { emitter.emit('unityHideBanner') }, timeout);
  }
}

function showNicknameChangeWindow() {
  console.log('UnityToBrowser', 'showNicknameChangeWindow')
  emitter.emit('showNicknameChangeWindow')
}

function showReadyPlayerFrame() {
  console.log('UnityToBrowser', 'showReadyPlayerFrame')
  emitter.emit('showAvatarChangeWindow')
}

function showAvatarChangeWindow() {
  console.log('UnityToBrowser', 'showAvatarChangeWindow')
  emitter.emit('showAvatarChangeWindow')
}

function setSpaceData(jsonSpaceData) {
  console.log('UnityToBrowser', 'setSpaceData')
  emitter.emit('setSpaceData', jsonSpaceData)
}

/*----*/
const getQueryParam = (param) => { return new URLSearchParams(window.location.search).get(param) }

window.onCompleteLoadingStage = onCompleteLoadingStage
window.getSpaceRoomId = getSpaceRoomId
window.showStartWindow = showStartWindow
window.getInitProperties = getInitProperties
window.showWindowLoading = showWindowLoading
window.onLoginResult = onLoginResult
window.onWorldLoadingFinished = onWorldLoadingFinished
window.onUnityReady = onUnityReady
window.showAuthWindow = showAuthWindow
window.onResetPasswordResult = onResetPasswordResult
window.unityShowBanner = unityShowBanner
window.showNicknameChangeWindow = showNicknameChangeWindow
window.showReadyPlayerFrame = showReadyPlayerFrame // deprecated?
window.showAvatarChangeWindow = showAvatarChangeWindow
window.setSpaceData = setSpaceData
