<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { ref } from 'vue';

const button = ref(null);

defineProps({
  light: {
    type: Boolean,
    default: false,
  },
  gradient: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  modal: {
    type: Boolean,
    default: false,
  },
  medium: {
    type: Boolean,
    default: true,
  },

  large: {
    type: Boolean,
    default: false,
  },
  xlarge: {
    type: Boolean,
    default: false,
  },
  xxlarge: {
    type: Boolean,
    default: false,
  },
})

defineExpose({
  button,
})
</script>

<template>
  <button
    ref="button"
    class="btn"
    :class="{
      'btn--light': light,
      'btn--gradient': gradient,
      'btn--small': small,
      'btn--modal': modal,
      'btn--medium': medium,
      'btn--large': large,
      'btn--xlarge': xlarge,
      'btn--xxlarge': xxlarge,
    }"
  >
    <slot></slot>
  </button>
</template>

<style scoped>

.btn {
  position: relative;
  cursor: pointer;
  border: 0;

  background: #000000;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  height: 40px;

  font: 500 18px 'Inter';
  text-align: center;
  color: #FFFFFF;

  padding: 0 40px;
}

.btn:hover:not(:disabled) {
  background: #3A3A3A;
}

.btn--light {
  background: #FFFFFF;
  color: #000000;
}

.btn--light:hover:not(:disabled) {
  border: 1px solid #000000;
}

.btn--gradient {
  background: white;
  background-clip: padding-box;
  color: #000000;
}

.btn--gradient:after {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  background: linear-gradient(270deg, #38FDEE 0%, #00C2FF 39.06%, #D663FF 100%);
  content: '';
  z-index: -1;
  border-radius: 8px;
}

.btn--gradient:hover:not(:disabled) {
  background: linear-gradient(270deg, #38FDEE 0%, #00C2FF 39.06%, #D663FF 100%);
  background-clip: border-box;
  color: #000000;
}

.btn--small {
  height: 27px;
  padding: 0 18px;
}

.btn--medium {
  height: 35px;
  padding: 0 42px;
}

.btn--modal {
  padding: 0 18px;
  font: 14px 'Inter';
}

.btn--large {
  height: 42px;
  padding: 0 48px;
}

.btn--xlarge {
  height: 48px;
  padding: 0 48px;
}

.btn--xxlarge {
  height: 60px;
  padding: 0 48px;
  min-width: 242px;
}

.btn:disabled {
  color: #3A3A3A;
  cursor: auto;
}

</style>
