<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { shallowRef, computed, watch } from 'vue'
import{ Button } from '@/components/inputs'
import '@/assets/notifications.scss'
import {getSpaceData, apiUrl} from "@/utils/settings"
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import {Enumify} from "enumify"
import emitter from "@/utils/emitter"

/*
  Events
 */
const emit = defineEmits(['accessAllowed', 'notify'])

/*
  Space
 */
const space = getSpaceData().space
const spaceACL = space.auth_requirement.acl[0]
const spaceId = space.id

/*
  Provider collecting
 */
class CollectingProvides extends Enumify {
  static launched = new CollectingProvides()
  static polling = new CollectingProvides()
  static finished = new CollectingProvides()
  static aborted = new CollectingProvides()

  static _ = this.closeEnum()
}

const collectingProviders = shallowRef(CollectingProvides.launched)
const walletConnectIndex = 0
const providersIndex = new Set()
const providers = [null]  // allocate place for WalletConnect provider which is instantiated by runtime.
const providersInfo = shallowRef([{name: 'WalletConnect'}])  // It is all time available option.

window.addEventListener("eip6963:announceProvider", ({detail}) => {
  // https://eips.ethereum.org/EIPS/eip-6963#provider-detail
  // provider property of type EIP1193Provider defined by EIP-1193.
  // https://eips.ethereum.org/EIPS/eip-1193#definitions
  // https://eips.ethereum.org/EIPS/eip-6963#provider-info
  const {info, provider} = detail
  if (providersIndex.has(info.uuid)) {
    return;
  }
  providersIndex.add(info.uuid)
  providers.push(provider)
  providersInfo.value.push(info)
});

const pollingProvidersBudgetMilliseconds = 5 * 1000;
const pollingProvidersIntervalMilliseconds = 300;

async function pollProviders() {
  const deadline = new Date().getTime() + pollingProvidersBudgetMilliseconds
  while (new Date().getTime() < deadline) {
    window.dispatchEvent(new Event("eip6963:requestProvider"))
    collectingProviders.value = CollectingProvides.polling
    const tick = new Promise((resolve) => {
      const timeoutID = setInterval(() => {
        clearTimeout(timeoutID)
        resolve()
      }, pollingProvidersIntervalMilliseconds)
    })
    await tick
  }
  collectingProviders.value = CollectingProvides.finished
}

// send polling to background.
pollProviders().catch((error) => {
  collectingProviders.value = CollectingProvides.aborted
  console.error('pollProviders failed', error)
})

/*
  Authenticating

  At the beginning authenticating process was a plain function. It was good enough because interaction with browser
  extension wallets is smooth and reliable. WalletConnect's interaction as opposite is clunky over network.
  It is required to retry. The process is divided by pieces to provide opportunity for end-user to retry.
 */
// Authenticating is internal representation. It could be used or not in composing messages for end-user.
class Authenticating extends Enumify {
  static none = new Authenticating()
  static connectWalletConnect = new Authenticating()
  static collectAccounts = new Authenticating()
  static signMessage = new Authenticating()
  static authenticate = new Authenticating()
  static disconnectWalletConnect = new Authenticating()

  static _ = this.closeEnum()
}

// Lifecycle is a user presentation of authentication process.
class AuthenticatingLifecycle extends Enumify {
  static initial = new AuthenticatingLifecycle()
  static processing = new AuthenticatingLifecycle()
  static stuck = new AuthenticatingLifecycle()
  static allowed = new AuthenticatingLifecycle()
  static denied = new AuthenticatingLifecycle()

  static _ = this.closeEnum()
}

const authenticating = shallowRef(Authenticating.none)

const authenticatingLifecycle = shallowRef(AuthenticatingLifecycle.initial)
const isProcessing = computed(() => authenticatingLifecycle.value === AuthenticatingLifecycle.processing)
const isDenied = computed(() => authenticatingLifecycle.value === AuthenticatingLifecycle.denied)
const isAllowed = computed(() => authenticatingLifecycle.value === AuthenticatingLifecycle.allowed)
watch(authenticatingLifecycle, function (newValue) {
  if (newValue === AuthenticatingLifecycle.allowed) {
    emit('accessAllowed')
  }
})

const processingMessageDelay = 10 * 1000
const lastError = shallowRef(null)

function extractLastError(error) {
  if (error instanceof Error) {
    return error.message
  } else if (typeof error === 'string' || error instanceof String) {
    return error
  } else if (Object.hasOwn(error, 'message')) {
    return error.message
  } else {
    return JSON.stringify(error)
  }
}

class AuthenticatingProcess {
  constructor(providerIndex, pipeline, cleanup) {
    // It is a storage for all data around process available for all stages from this.pipeline.
    this.context = new Map([
      ['providerIndex', providerIndex],
      ['provider', providers[providerIndex]]
    ])
    // It is ordered list of functions to approach authentication. They have interface
    // `[Authenticating, async function (context): void]`.
    this.pipeline = pipeline
    // It is callback to clean up resources on abort.
    this.cleanup = cleanup
    // It is emergency brake for the process in a case of changing wallet or whatever.
    this.controller = new AbortController()
    // It is index of next function to call.
    this.nextIndex = 0
  }

  async start(index) {
    index = index || 0
    // this.controller could be replaced in retry. Take reference for current signal.
    // XXX: it is more agronomic to pass Promise in arguments, but it is so tough job to make clean up resources.
    // The problems are cycle inbetween EventTarget and a listener, and fulfill Promise with rejection on signal.aborted
    // on start time. Hence, signal.aborted is checked on each step here and inside funcs from pipeline.
    const signal = this.controller.signal
    if (signal.aborted) {
      return
    }
    const processingMessage = setTimeout(() => {
      authenticatingLifecycle.value = AuthenticatingLifecycle.processing
      lastError.value = null
    },  processingMessageDelay)
    for (const [stage, func] of this.pipeline.slice(index)) {
      authenticating.value = stage
      this.nextIndex = index
      try {
        await func(this.context, signal)
      } catch (error) {
        authenticatingLifecycle.value = AuthenticatingLifecycle.stuck
        console.error('AuthenticatingProcess.start', this.context, stage, func, error)
        lastError.value = extractLastError(error)
        break
      }
      if (signal.aborted) {
        break
      }
      index += 1
    }
    clearTimeout(processingMessage)
  }

  async retry() {
    // There could be processing a pipeline at present.
    this.controller.abort("retry")
    this.controller = new AbortController()
    // Send to background.
    this.start(this.nextIndex)
  }

  async abort(reason) {
    this.controller.abort(reason)
    if (this.cleanup !== null) {
      this.cleanup(this.context)
    }
  }
}

let authenticatingProcess = null

async function collectAccounts(context, signal) {
  if (signal.aborted) {
    return
  }
  const provider = context.get('provider')
  // https://eips.ethereum.org/EIPS/eip-1102#eth_requestaccounts
  const accounts = await provider.request({ method: 'eth_requestAccounts', params: []})
  if (signal.aborted) {
    return
  }
  context.set('accounts', accounts)
}

const message = "The platform will verify access to the Metaverse space by checking the NFT in your wallet"
const encoder = new TextEncoder()
const uint8 = encoder.encode(message)
const hex = Array.from(uint8)
    .map((i) => i.toString(16).padStart(2, '0'))
    .join('')
const challenge = `0x${hex}`

async function signMessage(context, signal) {
  if (signal.aborted) {
    return
  }
  const provider = context.get('provider')
  const credentials = []
  const signatures = new Map()
  const rpc = []
  for (const address of context.get('accounts')) {
      // XXX: capture value in closure below.
      const a = address
      rpc.push(
          // https://eips.ethereum.org/EIPS/eip-1193#request
          provider.request({
              // https://docs.metamask.io/wallet/how-to/sign-data/#use-personal_sign
              // https://eips.ethereum.org/EIPS/eip-191
              "method": "personal_sign",
              "params": [challenge, address],
          }).then((s) => {signatures.set(a, s)})
      )
  }
  const results = await Promise.allSettled(rpc)
  if (signal.aborted) {
    return
  }
  if (signatures.size === 0) {
    throw results.filter((r) => r.status === "rejected")[0].reason
  }

  for (const [address, signature] of signatures.entries()) {
      credentials.push({
          address,
          message,
          signature,
      })
  }
  context.set('credentials', credentials)
}

async function authenticate(context, signal) {
  if (signal.aborted) {
    return
  }
  const credentials = context.get('credentials')
  const response = await fetch(new URL("/render/space/", apiUrl), {
    method: "POST",
    headers: {
       'Accept': 'application/json',
       'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      request: {
        space: spaceId,
      },
      acl: spaceACL,
      credentials,
    }),
  })
  if (signal.aborted) {
    return
  }

  const data = await response.json()
  if (signal.aborted) {
    return
  }
  if (response.status === 200) {
    emitter.emit('setSpaceData', data.content.space)
    space.access_token = data.content.access_token
    authenticatingLifecycle.value = AuthenticatingLifecycle.allowed
    emit('notify', {
      type: 'success',
      text: 'Woohoo, access granted!',
      duration: 5000,
    })
  } else if (response.status === 400) {
    authenticatingLifecycle.value = AuthenticatingLifecycle.denied
  } else  if (response.status === 500) {
    throw new Error(data.error)
  } else {
    throw new Error(JSON.stringify(data.error))
  }
}

function startBrowserExtension(index) {
  if (authenticatingProcess !== null) {
    authenticatingProcess.abort("user started new authentication cycle")
  }
  authenticatingProcess = new AuthenticatingProcess(index, [
      [Authenticating.collectAccounts, collectAccounts],
      [Authenticating.signMessage, signMessage],
      [Authenticating.authenticate, authenticate],
  ], null)
  authenticatingProcess.start()
}

const requirementChains = space.auth_requirement.chains.map((chain) => chain.caip_2).map((id) => id.split(':')[1]).map(parseInt)
// WalletConnect playground https://lab-walletconnect-modal.pages.dev/with-ethereum-provider
// The example is https://github.com/WalletConnect/modal/blob/95571fb4e96bd2a5c36214e657dc66aae0f1c8b4/laboratory/src/pages/with-ethereum-provider/index.tsx
// It is restrictively required to host space loader over localhost for local development.
// You can launch uvicorn as usual, set environment variables to localhost and proxy with command:
// sudo simpleproxy -L 80 -R localhost:9000
// At the moment of implementation Trust wallet (https://explorer.walletconnect.com/trust-wallet) was one of full
// support for walletconnect wallet.
// Project Id comes from account on https://cloud.walletconnect.com/.
const walletConnectProjectId = import.meta.env.VITE_DEV_WALLETCONNECT_PROJECT_ID || "dd81d4fa3315d10a823f771738b471ed"
const walletConnectInitialization = {
  projectId: walletConnectProjectId,
  // https://github.com/ChainAgnostic/CAIPs/blob/main/CAIPs/caip-2.md
  // Examples https://developer.levain.tech/products/graph/docs/levain-fundamentals/supported-chains
  // Generally speaking, any ethereum compatible wallet is fine. It is not unusual for people to use same address over
  // different networks.
  optionalChains: requirementChains,
  methods: ['eth_requestAccounts', 'personal_sign'],

  showQrModal: true,
  qrModalOptions: {
    themeVariables: {
      '--wcm-z-index': '1000',
    }
  }
}

async function connectWalletConnect(context, signal) {
  if (signal.aborted) {
    return
  }
  const provider = await EthereumProvider.init(walletConnectInitialization)
  if (signal.aborted) {
    return
  }
  await provider.connect()
  if (signal.aborted) {
    return
  }
  context.set('provider', provider)
}

async function disconnectWalletConnect(context) {
  if (!context.has('provider')) {
    return
  }
  const provider = context.get('provider')
  try {
    await provider.disconnect()
  } catch (error) {
    const message = extractLastError(error)
    // Suppress relay error.
    if (!message.startsWith("relayer")) {
      throw error
    }
  }
  context.delete('provider')
}

function startWalletConnectStart() {
  if (authenticatingProcess !== null) {
    authenticatingProcess.abort("user started new authentication cycle")
  }
  authenticatingProcess = new AuthenticatingProcess(walletConnectIndex, [
      [Authenticating.connectWalletConnect, connectWalletConnect],
      [Authenticating.collectAccounts, collectAccounts],
      [Authenticating.signMessage, signMessage],
      [Authenticating.authenticate, authenticate],
      [Authenticating.disconnectWalletConnect, disconnectWalletConnect],
  ], disconnectWalletConnect)
  authenticatingProcess.start()
}
</script>

<template>
  <div class="restrict-access">
      <div :class="[
          'restrict-access__header',
          {'restrict-access__header--restricted': isDenied, 'restrict-access__header--processing': isProcessing}
          ]">
        <p v-if="isDenied">
          Access denied
        </p>
        <p v-else>
          Private access
        </p>
      </div>
      <div :class="['restrict-access__body', {'restrict-access__body--processing': isProcessing}]">
        <p v-if="authenticatingLifecycle === AuthenticatingLifecycle.initial">This space requires a wallet connection and an NFT pass to get inside</p>
        <template v-else-if="authenticatingLifecycle === AuthenticatingLifecycle.processing">
          <p>Seems like it's gonna take a little longer. Please wait</p>
          <i class="restrict-access__ring-loader"></i>
        </template>
        <template v-else-if="authenticatingLifecycle === AuthenticatingLifecycle.stuck && [Authenticating.connectWalletConnect, Authenticating.collectAccounts, Authenticating.signMessage].includes(authenticating)">
          <p>
          Whoops. Something went wrong. Please try again
            </p>
          <div class="restrict-access__retry">
          <Button modal @click="authenticatingProcess.retry()">
            Retry
          </Button>
            </div>
        </template>
        <template v-else-if="authenticatingLifecycle === AuthenticatingLifecycle.stuck && (authenticating === Authenticating.authenticate && lastError !== null)">
          <p>
          {{ lastError }}
            </p>
          <div class="restrict-access__retry">
          <Button modal @click="authenticatingProcess.retry()">
            Retry
          </Button>
            </div>
        </template>
        <p v-else-if="authenticatingLifecycle === AuthenticatingLifecycle.denied">
          The NFT pass not found and access to the space is restricted
        </p>
        <p v-else-if="authenticatingLifecycle === AuthenticatingLifecycle.allowed">
          Wohoo, access granted!
        </p>
        <template v-else>
          <p>
          Authenticating is in unrecognized state ({{ authenticatingLifecycle.enumKey}}, {{ authenticating.enumKey }}, {{ lastError }}).
          You may or not encounter issues
          </p>
          <div class="restrict-access__retry">
          <Button modal @click="authenticatingProcess.retry()">
            Retry
          </Button>
            </div>
        </template>
      </div>
      <ul :class="['restrict-access__footer', {'restrict-access__footer--processing': isProcessing, 'restrict-access__footer--allowed': isAllowed}]">
        <li v-for="(info, index) in providersInfo.slice(1)" :key="index">
          <Button modal @click="startBrowserExtension(index+1)">
            {{ info.name }}
          </Button>
        </li>
        <li><Button modal @click="startWalletConnectStart()">WalletConnect</Button></li>
      </ul>
    </div>
</template>

<style scoped>
.restrict-access {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(19px);
  border-radius: 14px;
  font-family: Inter, sans-serif;
  /* copy from mobile view */
  padding-left: 20px;
  padding-right: 20px;

  /* push header down */
  padding-top: 45px;
}

.restrict-access__header {
  /* push body down */
  padding-bottom: 14px;

  p {
    font-family: Inter;
    font-size: 28px;
    font-weight: 500;
    line-height: 33.89px;
    text-align: center;
  }
}

.restrict-access__header--restricted {
  p {
    color: #F14953;
  }
}

.restrict-access__header--processing {
  display: none;
}

.restrict-access__body {
  p {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.78px;
    text-align: center;

    /* push footer down */
    padding-bottom: 25px;
  }

  &.restrict-access__body--processing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-family: Inter;
      font-size: 28px;
      font-weight: 500;
      line-height: 33.89px;
      text-align: center;

      /* reset padding, manage by ring */
      padding-bottom: 0;
    }
  }
}


.restrict-access__ring-loader {
  display: inline-block;
  --ring-size: 42px;
  --modal-content-padding: 12px;
  padding: calc(22px + var(--ring-size)/2) calc(var(--ring-size) / 2) calc(40px + var(--ring-size)/2 - var(--modal-content-padding));
  background-image: url(@/assets/images/ring_loader_black.svg);
  background-repeat: no-repeat;
  background-size: var(--ring-size) var(--ring-size);
  background-position: center center;
  content: "\0000a0";
  font-size: 0;
}

.restrict-access__retry {
  max-width: 425px;
  /* push footer down */
  padding-bottom: 25px;
  /* center */
  margin: 0 auto;

  .btn {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0 2px 6px 0 #00000033;
    width: 100%;
    height: 48px;
    color: #000000;
  }
}

.restrict-access__footer {
  max-width: 425px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 14px;
  column-gap: 22px;

  /* center */
  margin: 0 auto;

  /* push modal bottom side down */
  padding-bottom: 43px;

  li:only-of-type {
    margin: 0 auto;
  }

  .btn {
    min-width: 201px;

    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
    text-align: center;
  }
}

@media screen and (max-width: 487px) {
  .restrict-access__footer {
    align-items: center;
    justify-content: center;
  }
}

.restrict-access__footer--processing, .restrict-access__footer--allowed {
  display: none;
}
</style>
