<script setup>
import { computed } from 'vue'
import { getSpaceData, contentUrl } from '@/utils/settings'

const previewUrl = computed(() => {
  if (getSpaceData().space.data.preview) {
    return `${contentUrl}/${getSpaceData().space.data.preview}`
  }

  return ''
})

const worldPreviewStyle = computed(() => {
  if (previewUrl.value) {
    return { 'background-image': `url(${previewUrl.value})` }
  }

  return { }
})

</script>

<template>
  <div class="no-mobile__wrapper">
    <h1 class="title">{{ getSpaceData().space.data.name }}</h1>

    <div class="world-preview" :style="worldPreviewStyle"></div>

    <div class="error">
      <div class="error__text">Visit this world using Chrome, Firefox or Edge on Desktop</div>
    </div>

    <div class="description">
      <div class="description__text">
        {{ getSpaceData().space.data.description }}
      </div>
    </div>

  </div>
</template>

<style scoped>
.no-mobile__wrapper {
  background: #131E28;
  backdrop-filter: blur(10px);
  min-height: 100vh;
  padding: 31px 34px;
}

.title {
  font: 500 32px/48px 'Inter';
  text-align: center;
  color: #FFFFFF;
  word-break: break-all;
}

.world-preview {
  max-width: 350px;
  height: 256px;
  margin: 20px auto 0;
  border-radius: 12px;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.error {
  background: #D2D9E7;
  border-radius: 40px;
  margin: 23px auto 0;
  display: table;
}

.error__text {
  font: 500 16px/24px 'Inter';
  color: #131E28;
  padding: 9px 16px;
  text-align: center;
}

.description {
  margin-top: 23px;
}

.description__text {
  font: 300 16px/24px 'Inter';
  color: #FFFFFF;
}
</style>
