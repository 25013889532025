<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { ref, computed } from 'vue'
import LoginTitle from '@/components/login/LoginTitle.vue'
import { TextInput, Button } from '@/components/inputs'
import emitter from '@/utils/emitter'
import { sendMessageToUnity } from '@/utils/unityBridgeBase'

const email = ref('')
const password = ref('')
const loginErrorText = ref('')
const inProgress = ref(false)
const emailValid = ref(true)

emitter.on('onLoginResult', (isError, errorText) => {
  inProgress.value = false
  if (isError) {
    loginErrorText.value = errorText
  } else {
    loginErrorText.value = ''
    emit('authorize')
  }
})

function loginClick() {
  inProgress.value = true
  sendRequestToUnity(email.value, password.value)
}

function sendRequestToUnity(email, password) {
  const authData = { email: email, password: password }

  sendMessageToUnity('SetAuthToken', JSON.stringify(authData))
}

const emailUnderlineText = computed(() => {
  if (!emailValid.value) {
    return 'Enter a valid email'
  }
  if (loginErrorText.value) {
    return loginErrorText.value
  }
  return ''
})

const emit = defineEmits(['authorize', 'forgotPassword'])
</script>

<template>
  <div class="panel-login__wrapper">
    <LoginTitle>Login</LoginTitle>

    <div class="inputs__wrapper">
      <TextInput
        autofocus
        label="Email"
        v-model="email"
        :underlineFirstText="emailUnderlineText"
        underlineFirstType="error"
        @keydown.enter="loginClick"
        email
        @update:valid="emailValid = $event"
      />
      
      <TextInput
        label="Password"
        v-model="password"
        underlineLastText="Forgot password?"
        underlineLastType="link"
        @keydown.enter="loginClick"
        @underlineLastClick="emit('forgotPassword')"
        password
      />
    </div>
    
    <Button
      xlarge
      :disabled="!email || !password || inProgress || !emailValid"
      @click="loginClick"
      class="login-btn"
    >
      Login
    </Button>
  </div>
</template>

<style scoped>
.panel-login__wrapper {
  display: flex;
  flex-direction: column;
}

.inputs__wrapper {
  margin-top: 37px;
}

.login-btn {
  align-self: center;
  margin-top: 36px;
}
</style>
