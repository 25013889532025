import UAParser from 'ua-parser-js'

export function checkEmail(email) {
  const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email.match(mailformat)
}

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export let isMobile = false
try {
  const parser = new UAParser()
  isMobile = parser.getDevice().type === 'mobile'
}
catch (e) {
  console.error('Error detect browser: ' + e)
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}
