<script setup>
import { computed } from 'vue'

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
})

const warningClass = computed(() => {
  return `unity-warning--${props.type}`
})

</script>

<template>
  <div class="unity-warning__wrapper" :class="warningClass">
    <p>{{ text }}</p>
  </div>
</template>

<style scoped>

.unity-warning__wrapper {
  position: absolute;
  display: flex;
  left: 50%;
  top: 12px;
  transform: translate(-50%);
  width: 384px;
  justify-content: center;
  padding: 13px 29px;
  font: 400 12px/18px "Inter", sans-serif;
  background: #131e28;
  border-radius: 40px;
  z-index: 10;
}

.unity-warning--error {
  border: 1px solid #f14953;
  color: #f14953;
}

.unity-warning--warning {
  border: 1px solid #f1b849;
  color: #f1b849;
}

.unity-warning--info {
  border: 1px solid #2ad746;
  color: #2ad746;
}

</style>
