<script setup>
import ProgressBar from '@/components/ProgressBar.vue'
import emitter from '@/utils/emitter'
import { ref } from 'vue';
import { getSpaceData } from '@/utils/settings'

const props = defineProps({
  mode: {
    type: String,
    default: 'load-core', // variants load-core | load-space
  },
})

const progressLoadCoef = 0.5
const progressInitCoef = 1 - progressLoadCoef
const progress = ref(0)
const worldName = ref(getSpaceData().space.data.name)

function setProgress(value) {
  progress.value = 100 * value * progressLoadCoef
}

emitter.on('onCompleteLoadingStage', (stageNumber, stageCount) => {
  const stagePercent = stageNumber / stageCount
  if (props.mode === 'load-core') {
    progress.value = 100 * progressLoadCoef + 100 * stagePercent * progressInitCoef
  } else {
    progress.value = 100 * stagePercent
  }

  if (progress.value > 100) {
    progress.value = 100
  }
  console.log('onCompleteLoadingStage', stageNumber, stageCount, progress.value)
})

emitter.on('onWorldLoadingFinished', () => {
  progress.value = 100
})

emitter.on('setSpaceData', (jsonSpaceData) => {
  worldName.value = jsonSpaceData.name
})

defineExpose({ setProgress })
</script>

<template>
  <div class="loading-panel__wrapper">

    <slot name="header"></slot>

    <p class="world-name">{{ worldName }}</p>

    <ProgressBar
      :progress="progress"
    />

    <slot name="footer"></slot>
  </div>
</template>

<style>

.loading-panel__wrapper {
  margin-top: auto;
  margin-left: 51px;

  display: flex;
  flex-direction: column;
  max-width: 795px;
  padding: 35px 55px 55px;

  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(19px);
  border-radius: 14px 14px 0px 0px;

  overflow: hidden;

  position: fixed;
  bottom: 0;
  width: 100%;
}

@media (max-width: 992px) {
  .loading-panel__wrapper {
    margin-left: 12px;
    margin-right: 12px;
    padding: 35px 23px 55px;
  }
}

.world-name {
  font: 500 38px/46px 'Inter';
  color: #000000;
  text-align: left;
}


@media (max-width: 992px) {
  .world-name {
    font: 500 32px/39px 'Inter';
  }
}

</style>
