<script setup>
import { ref, computed } from 'vue'
import { TextInput } from '@/components/inputs'
import { Button } from '@/components/inputs'
import { getSpaceData } from '@/utils/settings'
import Dialog from '@/components/Dialog.vue'
import AvatarsSlider from '@/components/AvatarsSlider.vue'
import ChangeAvatarTitle from '@/components/ChangeAvatarTitle.vue'
import { currentAvatar, currentNickname, sendMessageSelectAvatar, sendMessageSetNickname } from '@/utils/customization.js'
import Rpm from '@/components/Rpm.vue'

const nickname = ref(currentNickname)
const newAvatar = ref(currentAvatar)

const isRpmVisible = ref(false)

const customNicknameDisabled = computed(() => {
  return getSpaceData().space.data.custom_nicknames_disabled
})

function isDefaultCmsAvatarSelected() {
  return currentAvatar?.type === 'cms' && currentAvatar?.data?.is_platform_default
}

function changeClicked() {
  if (newAvatar.value &&
      newAvatar.value.type !== 'rpm' &&
      newAvatar.value.data.id !== currentAvatar.data.id) {

    sendMessageSelectAvatar(newAvatar.value.data)
  }

  if (nickname.value !== currentNickname) {
    sendMessageSetNickname(nickname.value)
  }

  emit('close')
}

function onRpmClose(isSelected) {
  if (isSelected) {
    if (nickname.value !== currentNickname) {
      sendMessageSetNickname(nickname.value)
    }
    
    emit('close')
  }
  isRpmVisible.value = false
}

const emit = defineEmits(['close']);
</script>

<template>
  <Dialog
    @close="emit('close')"
    pads="thin"
    class="change-avatar-dialog"
  >
    <TextInput
      placeholder="Whats your name?"
      special
      v-model="nickname"
      @keydown.enter="enterButton.button.click()"
      ref="nicknameInput"
      class="nickname-input"
      v-if="!customNicknameDisabled"
    />

    <ChangeAvatarTitle
      @rpm="isRpmVisible = true"
    />

    <AvatarsSlider
      class="avatars-slider"
      v-model="newAvatar"
      :showRpmItem="currentAvatar?.type === 'rpm'"
      :showMore="!isDefaultCmsAvatarSelected()"
    />

    <Button
      @click="changeClicked"
      large
    >
      Change
    </Button>

  </Dialog>

  <Rpm
    v-if="isRpmVisible"
    @close="onRpmClose"
  />
</template>

<style scoped>

.nickname-input {
  margin-bottom: 10px;
}

.avatars-slider {
  margin-top: 28px;
  margin-bottom: 28px;
}

.change-avatar-dialog :deep(.dialog__wrapper) {
  max-width: 795px;
}
</style>