<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { tns } from "tiny-slider"
import 'tiny-slider/dist/tiny-slider.css';
import { getSpaceData, contentUrl } from '@/utils/settings.js'
import showMoreImage from '@/assets/images/slider-show-more.png'

const props = defineProps({
  showRpmItem: {
    type: Boolean,
    required: false,
  },
  modelValue: {
    type: Object || String,
  },
  showMore: {
    type: Boolean,
    default: false,
  },
})

const sliderItemRpm = ref(null)
const sliderItemsCms = ref([])

const sliderEl = ref(null)
const sliderElFull = ref(null)

const showMoreIsClicked = ref(false)

const avatars = computed(() => {
  return getSpaceData().space.data.avatars
})

const sliderItemsFull = computed(() => {
  let result = avatars.value

  result.forEach((item) => {
    item.thumbnailUrl = getAvatarThumbnailUrl(item)
    item.isMore = false
  })

  return result
})

const sliderItems = computed(() => {
  let result = [...sliderItemsFull.value]

  const userAvatarsExists = !!result.find(avatar => avatar.is_platform_default)
  const defaultAvatarExists = !!result.find(avatar => !avatar.is_platform_default)

  const showMoreCard = props.showMore && userAvatarsExists && defaultAvatarExists && !showMoreIsClicked.value

  if (showMoreCard) {
    result = result.filter(avatar => !avatar.is_platform_default)

    result.push({
      isMore: true,
      thumbnailUrl: showMoreImage,
      id: -1,
    })
  }

  return result
})

function getAvatarThumbnailUrl(avatar) {
  if (avatar.content_item.media.poster) {
    return `${contentUrl}/${avatar.content_item.media.poster}`
  } else {
    return `${contentUrl}/${avatar.content_item.thumbnail}`
  }
}

function getSliderId() {
  if (window.tinySliderNumber === undefined) {
    window.tinySliderNumber = 0
  }
  else {
    window.tinySliderNumber++
  }

  return `avatarsSlider${window.tinySliderNumber}`
}

const sliderId = ref(getSliderId())
const sliderIdFull = ref(getSliderId())

function createSlider(id) {
  return tns({
    container: `#${id}`,
    items: 2,
    slideBy: 1,
    autoplay: false,
    controls: true,
    nav: false,
    mouseDrag: true,
    loop: false,
    controlsText: [ '', '' ],
    responsive: {
      428: {
        items: 4,
      },
      550: {
        items: 5,
      },
      768: {
        items: 6,
      },
      992: {
        items: 7,
      },
    },
  });
}

onMounted(() => {
  sliderEl.value = createSlider(sliderId.value)

  sliderElFull.value = createSlider(sliderIdFull.value)

  updateSelection()
})

function sliderItemClick(avatar) {
  if (avatar.isMore) {
    const sliderInfo = sliderEl.value.getInfo()
    sliderElFull.value.goTo(sliderInfo.index)

    showMoreIsClicked.value = true
    return
  }

  if (avatar === 'rpm') {
    emit('update:modelValue', { type: 'rpm', data: null })
  } else {
    emit('update:modelValue', { type: 'cms', data: avatar })
  }
}

watch(() => props.modelValue, () => {
  updateSelection()
})

function clearSelection() {
  if (props.showRpmItem) {
    sliderItemRpm.value.classList.remove('selected')
  }

  sliderItemsCms.value.forEach((item) => {
    item.classList.remove('selected')
  })
}

function updateSelection() {
  const currAvatar = props.modelValue

  if (currAvatar?.type === 'rpm') {
    clearSelection()
    sliderItemRpm.value.classList.add('selected')
  } else if (currAvatar?.data) {
    clearSelection()
    const avatarId = currAvatar?.data?.id
    sliderItemsCms.value.forEach((item) => {
      if (item.dataset.avatarId === avatarId) {
        item.classList.add('selected')
      }
    })
  }
}

const emit = defineEmits(['update:modelValue'])
</script>

<template>
  <div class="avatars-slider__wrapper">

    <div v-show="!showMoreIsClicked">
      <div class="avatars-slider" :id="sliderId">

        <div v-if="showRpmItem">
          <div
            class="item item-rpm"
            ref="sliderItemRpm"
            @click="selectAvatar('rpm')"
          >
          </div>
        </div>

        <div v-for="item, index in sliderItems" :key="index">
          <div
            class="item"
            :style="{ 'background-image': `url(${item.thumbnailUrl})` }"
            ref="sliderItemsCms"
            :data-avatar-id="item.id"
            @click="sliderItemClick(item)"
          >
          </div>
        </div>
      </div>
    </div>

    <div v-show="showMoreIsClicked">
      <div class="avatars-slider" :id="sliderIdFull" >

        <div v-if="showRpmItem">
          <div
            class="item item-rpm"
            ref="sliderItemRpm"
            @click="selectAvatar('rpm')"
          >
          </div>
        </div>

        <div v-for="item, index in sliderItemsFull" :key="index">
          <div
            class="item"
            :style="{ 'background-image': `url(${item.thumbnailUrl})` }"
            ref="sliderItemsCms"
            :data-avatar-id="item.id"
            @click="sliderItemClick(item)"
          >
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<style scoped>
.avatars-slider__wrapper {
  position: relative;
  min-height: 144px;
}

.item {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;
  width: 86px;
  height: 144px;
  border: 1px solid #999999;
  border-radius: 10px;
  margin: 0 auto;
  position: relative;
  overflow: visible;
}

.item-rpm {
  background-image: url(@/assets/images/rpm-avatar.png);
  background-size: contain;
}

.item:hover,
.item.selected {
  /*border: 1px solid transparent;*/
}

.item:hover:after,
.item.selected:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0, 0.1);
  mix-blend-mode: normal;
  border: 2px solid #000000;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  content: '';
}

:deep(.avatars-slider) {
  padding: 4px 0;
}

:deep(.tns-controls > button) {
  position: absolute;
  top: 50%;
  width: 37px;
  height: 37px;
  border: none;
  background: url('@/assets/images/slider-arrow-active.svg') no-repeat center center;
}

@media (max-width: 992px) {
  :deep(.tns-controls > button) {
    display: none;
  }
}

:deep(.tns-controls > button:disabled) {
  background-image: url('@/assets/images/slider-arrow-inactive.svg');
  cursor: default;
}

:deep(.tns-controls > button:not(:disabled):hover) {
  background-image: url('@/assets/images/slider-arrow-hover.svg');
}

:deep(button[data-controls=prev]) {
  left: -37px;
  transform: translateY(-50%);
}

:deep(button[data-controls=next]) {
  right: -37px;

  transform: translateY(-50%) rotate(180deg);
}
</style>