import { sendMessageToUnity } from '@/utils/unityBridgeBase'
import emitter from '@/utils/emitter'

export let currentNickname = ''

emitter.on('onNicknameChange', (nickname) => {
  currentNickname = nickname
})

export const currentAvatar = {
  'type': '',
  'data': '',
}

export function sendMessageSelectAvatar(avatar) {
  currentAvatar.type = 'cms'
  currentAvatar.data = avatar
  console.log('PreselectAvatar', avatar.id)
  sendMessageToUnity('PreselectAvatar', avatar.id)
}

export function sendMessageSelectRpmAvatar(rpmUrl) {
  currentAvatar.type = 'rpm'
  currentAvatar.data = rpmUrl
  console.log('OnAvatarExported', rpmUrl)
  sendMessageToUnity('OnAvatarExported', rpmUrl)
}

export function sendMessageSetNickname(nickname) {
  currentNickname = nickname
  sendMessageToUnity('HideNicknameFrame', nickname)
}