export const apiUrl = import.meta.env.VITE_DEV_URL_API
export const contentUrl = import.meta.env.VITE_DEV_URL_CONTENT

let spaceDataParsed = null;
export function getSpaceData() {
  if (spaceDataParsed == null) {
    setSpaceDataJson(document.getElementById("data").innerText)
  }
  
  return spaceDataParsed
}

export function setSpaceDataJson(jsonSpaceData) {
  spaceDataParsed = JSON.parse(jsonSpaceData)
}
