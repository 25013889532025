export function removeSaTokenFromUrl() {
  const urlParams = new URLSearchParams(window.location.search)

  if (urlParams.has('sa-token')) {
    urlParams.delete('sa-token')
    let newUrlSearch = urlParams.toString()
    if (newUrlSearch !== '') {
      newUrlSearch = '?' + newUrlSearch
    }
    history.replaceState(null, '', window.location.pathname + newUrlSearch)
  }
}
