<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import CloseIcon from 'vue-material-design-icons/Close.vue';
import { sendMessageSelectRpmAvatar } from '@/utils/customization.js';

const emit = defineEmits(['close']);

onMounted(() => {
  window.addEventListener('message', rpmEventsHandler);
  document.addEventListener('message', rpmEventsHandler);
});

onBeforeUnmount(() => {
  document.removeEventListener('message', rpmEventsHandler);
  window.removeEventListener('message', rpmEventsHandler);

  emit('close', false);
});

const isFrameLoaded = ref(false);
const isFrameError = ref(false);

function iframeOnLoad() {
  isFrameLoaded.value = true;
}

function iframeOnError() {
  isFrameError.value = true;
}

function rpmCloseClick() {
  emit('close', false);
}

function rpmEventsHandler(event) {
  if (event.origin === "https://arhead.readyplayer.me") {
    sendMessageSelectRpmAvatar(event.data)

    emit('close', true);
  }
}

</script>

<template>
  <div class="overlay">
    <div class="rpm__wrapper">
      <CloseIcon
        v-if="isFrameLoaded || isFrameError"
        class="modal-close"
        @click="rpmCloseClick"
        :size="33"
      />

      <iframe
        src="https://arhead.readyplayer.me"
        allow="camera *; microphone *;"
        class="avatar-modal__iframe"
        :onload="iframeOnLoad"
        :onerror="iframeOnError"
      ></iframe>
    </div>
  </div>
</template>

<style scoped>
.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.rpm__wrapper {
  /*display: flex;
  position: relative;
  max-height: 100%;
  max-width: 100%;*/
  position: relative;
  padding: 10px;
  /*transition: opacity 500ms;*/
  background: none;
}

:deep(.avatar-modal__iframe) {
  display: flex;
  width: 1280px;
  height: 800px;
  max-height: 100%;
  max-width: 100%;
  z-index: 10;
}


.modal-close {
  position: absolute;
  right: -13px;
  top: -14px;
  cursor: pointer;

  background: #FFFFFF;
  border: 1px solid #000000;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.material-design-icon__svg) {
  color: black;
}


</style>
