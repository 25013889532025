<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { ref } from 'vue';
import { TextInput, Button } from '@/components/inputs'
import Dialog from '@/components/Dialog.vue';
import { sendMessageSetNickname } from '@/utils/customization.js'

const newNickname = ref('')

function saveClicked() {
  sendMessageSetNickname(newNickname.value)
  emit('close')
}

const emit = defineEmits(['close']);
</script>

<template>
  <Dialog
    @close="emit('close')"
    :class="{ 'panel-check-email': currentPanel === 'checkEmail' }"
    title="Change your username"
  >
    <div class="login-dialog__wrapper">
      <TextInput
        autofocus
        v-model="newNickname"
        @keydown.enter="saveClicked"
      />
    </div>

    <template #footer>
      <Button xxlarge class="btn-save" @click="saveClicked">Save</Button>
    </template>
  </Dialog>
</template>

<style scoped>
.panel-check-email :deep(.dialog__wrapper) {
  padding-left: 68px;
  padding-right: 68px;
}

.btn-save {
  display: table;

}
</style>
