<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { ref, computed } from 'vue';
import LoginTitle from '@/components/login/LoginTitle.vue';
import { TextInput, Button } from '@/components/inputs'
import emitter from '@/utils/emitter';
import { sendMessageToUnity } from '@/utils/unityBridgeBase'

const email = ref('')
const emailValid = ref(true)
const inProgress = ref(false)
const resetErrorText = ref('')

emitter.on('onResetPasswordResult', (isError, errorText) => {
  console.error('Reset password error: ', errorText)

  inProgress.value = false
  if (isError) {
    resetErrorText.value = 'Something went wrong.'
  } else {
    resetErrorText.value = ''
    emit('resetPassword')
  }
})

const emailUnderlineText = computed(() => {
  if (!emailValid.value) {
    return 'Enter a valid email'
  }
  if (resetErrorText.value) {
    return resetErrorText.value
  }
  return ''
})


function sendClick() {
  inProgress.value = true
  sendRequestToUnity(email.value)
}

function sendRequestToUnity(email) {
  sendMessageToUnity('ResetPassword', email);
}

const emit = defineEmits(['resetPassword']);
</script>

<template>
  <div class="panel-reset-password__wrapper">
    <LoginTitle>Reset password</LoginTitle>

    <p class="instruction">Enter the email associated with your account and we send instructions to restore the password</p>

    <div class="inputs__wrapper">
      <TextInput
        label="Email"
        autofocus
        v-model="email"
        underlineFirstType="error"
        :underlineFirstText="emailUnderlineText"
        @keydown.enter="sendClick"
        @update:valid="emailValid = $event"
        email
      />
    </div>
    
    <Button
      xlarge
      :disabled="!email || !emailValid"
      @click="sendClick"
      class="submit-btn"
    >
      Send
    </Button>
  </div>
</template>

<style scoped>
.panel-reset-password__wrapper {
  display: flex;
  flex-direction: column;
}

.instruction {
  font: 400 14px/17px'Inter';
  text-align: center;
  color: #000000;
  margin-top: 19px;
}

.inputs__wrapper {
  margin-top: 37px;
}

.submit-btn {
  align-self: center;
  margin-top: 20px;
}
</style>
