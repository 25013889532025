import emitter from '@/utils/emitter';

const browserPrefixes = ['moz', 'ms', 'o', 'webkit']
var isVisible = undefined

function getHiddenPropertyName(prefix) {
  return (prefix ? prefix + 'Hidden' : 'hidden');
}

function getVisibilityEvent(prefix) {
  return (prefix ? prefix : '') + 'visibilitychange';
}

function getBrowserPrefix() {
  for (var i = 0; i < browserPrefixes.length; i++) {
    if(getHiddenPropertyName(browserPrefixes[i]) in document) {
      return browserPrefixes[i];
    }
  }

  return null;
}

var browserPrefix = getBrowserPrefix(),
    hiddenPropertyName = getHiddenPropertyName(browserPrefix),
    visibilityEventName = getVisibilityEvent(browserPrefix);


const visibilityListenerInternal = (forcedFlag) => {
  let newVisibility

  if(typeof forcedFlag === "boolean") {
    newVisibility = forcedFlag
  } else {
    newVisibility = !document[hiddenPropertyName]   
  }

  if (isVisible !== newVisibility) {
    isVisible = newVisibility
    emitter.emit('onVisibilityChanged', isVisible)
  }
}

document.addEventListener(visibilityEventName, visibilityListenerInternal, false);

document.addEventListener('focus', function() {
  visibilityListenerInternal(true);
}, false);

document.addEventListener('blur', function() {
  visibilityListenerInternal(false);
}, false);

window.addEventListener('focus', function() {
  visibilityListenerInternal(true);
}, false);

window.addEventListener('blur', function() {
  visibilityListenerInternal(false);
}, false);
