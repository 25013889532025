import AgoraRTC from "agora-rtc-sdk-ng"
import { sendMessageToUnity } from '@/utils/unityBridgeBase'

const voiceMicrophoneStates = {
  PERMISSION_DENIED: "PERMISSION_DENIED",
  NO_MICROPHONES: "NO_MICROPHONES",
  CONNECTED: "CONNECTED",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
}

var voiceClient = null
var voiceTrack = null
var microphoneState = null
var voiceUsers = {}

async function voiceInit() {
  try {
    voiceClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" })

    voiceClient.on("user-published", async function (user, mediaType) {
      voiceUsers[user.uid] = user
      await voiceClient.subscribe(user, mediaType)
      user.audioTrack.play()
    })
    voiceClient.on("user-unpublished", function (user) {
      delete voiceUsers[user.uid]
    })

    voiceClient.on("token-privilege-will-expire", async function () {
      sendMessageToUnity('RequireNewToken')
    })

    voiceClient.on("token-privilege-did-expire", async function () {
      sendMessageToUnity('OnRenewTokenFailed')
    })

    voiceClient.on("connection-state-change", function(curState, revState, reason) {
      console.log(`connection-state-change ${curState} ${revState} ${reason}`)
    })

    sendMessageToUnity('OnAgoraInitSuccess')
  } catch (e) {
    console.error(e)
    sendMessageToUnity('VoiceInitFailed')
  }
}

function voiceGetConnectionState() {
  return voiceClient.connectionState
}

async function voiceJoinRoom(appid, room, token) {
  try {
    if (voiceClient.connectionState === "CONNECTED") {
      if (!(await voiceLeaveRoom())) {
        throw Error("Leave room error")
      }
    }

    microphoneState = await voiceInitMicrophone()
    if (microphoneState === voiceMicrophoneStates.CONNECTED) {
      voiceSetMuted(1)
      voiceTrack.on("track-ended", function () {
        console.log("ARH track-ended")
        sendMessageToUnity('VoiceTrackCloed')
      })
    }

    const uid = await voiceClient.join(appid, room, token)

    if (microphoneState === voiceMicrophoneStates.CONNECTED) {
      await voiceClient.publish([voiceTrack])
    }

    window.unityObj.SendMessage(
      'BrowserBridge',
      'VoiceJoinRoomResult',
      JSON.stringify({result: 'success', uid: uid, microphoneState: microphoneState})
    )
  } catch (e) {
    console.error(e)
    window.unityObj.SendMessage(
      'BrowserBridge',
      'VoiceJoinRoomResult',
      JSON.stringify({result: 'failed'})
    )
  }
}

async function voiceLeaveRoom() {
  if (voiceClient === null) return true

  try {
    if (microphoneState === voiceMicrophoneStates.CONNECTED) {
      voiceTrack.close()
      voiceTrack = null
      microphoneState = null
    }
    await voiceClient.leave()
    sendMessageToUnity('LeftVoiceRoom')
    return true
  } catch (e) {
    console.error(e)
    sendMessageToUnity('LeftVoiceRoomFailed')
    return false
  }
}

async function voiceRenewToken(token) {
  try {
    await voiceClient.renewToken(token)
  } catch (e) {
    console.error(e)
    sendMessageToUnity('OnRenewTokenFailed')
  }
}

async function voiceSetMuted(muted) {
  try {
    if (microphoneState === voiceMicrophoneStates.CONNECTED) {
      await voiceTrack.setMuted(!!muted)
    }
  } catch (e) {
    console.error(e)
  }
}

async function voiceInitMicrophone() {
  try {
    const microphones = await AgoraRTC.getMicrophones()

    if (!microphones.length) {
      return voiceMicrophoneStates.NO_MICROPHONES
    }

    voiceTrack = await AgoraRTC.createMicrophoneAudioTrack()

    return voiceMicrophoneStates.CONNECTED
  } catch (e) {
    if (e.code === "PERMISSION_DENIED") {
      return voiceMicrophoneStates.PERMISSION_DENIED
    } else {
      return voiceMicrophoneStates.UNKOWN_ERROR
    }
  }
}

export default {
  voiceInit,
  voiceGetConnectionState,
  voiceJoinRoom,
  voiceLeaveRoom,
  voiceRenewToken,
  voiceSetMuted,
}