<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { ref } from 'vue';
import PanelLogin from './PanelLogin.vue';
import Dialog from '@/components/Dialog.vue';
import PanelResetPasword from './PanelResetPasword.vue';

const emit = defineEmits(['close']);
const currentPanel= ref('login')

function closeClick() {
  if (currentPanel.value === 'login') {
    emit('close')
  } if (currentPanel.value === 'resetPassword') {
    currentPanel.value = 'login'
  } else {
    currentPanel.value = 'login'
  }
}

</script>

<template>
  <Dialog
    @close="closeClick"
    :class="{ 'panel-check-email': currentPanel === 'checkEmail' }"
  >
    <div class="login-dialog__wrapper">
      <PanelLogin
        @authorize="emit('close')"
        @forgotPassword="currentPanel = 'resetPassword'"
        v-if="currentPanel === 'login'"
      />
      <PanelResetPasword
        @resetPassword="currentPanel = 'checkEmail'"
        v-if="currentPanel === 'resetPassword'"
      />
      <PanelCheckEmail
        @close="currentPanel = 'login'"
        v-if="currentPanel === 'checkEmail'"
      />
    </div>
  </Dialog>
</template>

<style scoped>
.login-dialog__wrapper {
  
}

.panel-check-email :deep(.dialog__wrapper) {
  padding-left: 68px;
  padding-right: 68px;
}
</style>
